<template>
    <el-dialog
      title="插入模板"
      width="700px"
      top='5vh'
      :visible.sync="show"
      :append-to-body="true"
      :before-close="handleCancel"
    >
  <div class="insert-template">
    <div class="filter-operation">
      <div>
        <el-select
          v-model="categoryId"
          placeholder="请选择分类"
          style="width: 180px"
          filterable
          @change="handlePageChange"
        >
          <el-option
            v-for="item in classifyList"
            :label="item.name"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </div>
      <div>
        <el-input
          v-model="search"
          placeholder="请输入标题"
          style="width: 200px; margin-right: 20px"
        >
          <el-button
            @click="handleSearch"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <el-button @click="handleAddTemplate" type="primary"
          >新增模板</el-button
        >
      </div>
    </div>
    <div class="table-info">
      <el-table
        :data="templateList"

        max-height="620px"
        v-loading="loading"
        highlight-current-row
        @current-change="(row) => (currentTemplate = row)"
        style="width: 100%; margin: 20px 0"
      >
        <el-table-column prop="select" label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              type="radio"
              :value="currentTemplate ? currentTemplate.id : null"
              :label="scope.row.id"
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="100"></el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          width="100"
        ></el-table-column>
        <el-table-column label="内容" prop="content">
          <template slot-scope="scope">
            <span>{{ scope.row.content }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center">
        <el-button type="" @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="handleOk">确认</el-button>
      </div>
    </div>
    <el-drawer
      title="新增模板"
      size="700px"
      :append-to-body="true"
      :visible.sync="isAddTemplate"
    >
      <div>
        <div class="title">
          <el-input v-model="title" placeholder="请输入标题"></el-input>
        </div>
        <div class="input-textarea">
          <el-input
            type="textarea"
            :autosize="{ minRows: 35 }"
            v-model="content"
            placeholder="请输入模板内容"
          ></el-input>
        </div>
        <div class="button-list">
          <el-button type="" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确认</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
    </el-dialog>
</template>

<script>
import { getMessageModule, addMessageModule } from '@/api/classify'
export default {
  name: 'insertTemplate',
  props: ['classifyList', 'show'],
  data () {
    return {
      categoryId: null,
      pageSize: 15,
      search: '',
      page: 1, // 当前页
      templateList: [],
      loading: true,
      isAddTemplate: false,
      currentTemplate: null,
      title: '', // 新增模板标题
      content: '' // 新增模板内容
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.categoryId = this.classifyList[0].id
          this.handlePageChange()
        }
      },
      immediate: true
    }
  },
  mounted () {
    // this.initData();
  },
  methods: {
    handlePageChange (val) {
      this.loading = true
      if (val) {
        this.categoryId = val
      }
      getMessageModule({
        categoryId: this.categoryId,
        page: this.page,
        pageSize: 15,
        search: this.search
      }).then((res) => {
        this.templateList = res.list
        this.loading = false
      })
    },
    handleAddTemplate () {
      this.isAddTemplate = true
    },
    handleClose () {
      this.isAddTemplate = false
    },
    handleSearch () {
      this.handlePageChange()
    },
    handleConfirm () {
      addMessageModule({
        categoryId: this.categoryId,
        content: this.content,
        title: this.title
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: '修改成功!',
            type: 'success'
          })
          this.page = 1
          this.handlePageChange()
        })
        .finally(() => {
          this.resetData()
          this.handleClose()
        })
    },
    handleCancel () {
      this.$emit('close')
    },
    handleOk () {
      this.$emit('handleOk', this.currentTemplate)
      this.$emit('close')
    },
    resetData () {
      this.content = ''
      this.search = ''
      this.title = ''
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__body {
  overflow-y: auto;
}
/deep/ .el-drawer {
  padding: 20px;
  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}
.insert-template {
  .filter-operation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.button-list {
  text-align: center;
}
.input-textarea {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
}
.title {
  box-sizing: border-box;
  padding: 20px;
}
.button-list {
  text-align: center;
}
</style>
