export let activityZHY = {
    list:[
    {
        label:'100书券',
        value:5
    },
    {
        label:'200书券',
        value:2
    },
    {
        label:'300书券',
        value:3
    },
    {
        label:'500书券',
        value:4
    },
    {
        label:'600书券',
        value:6
      }
]
    
}

export let activityYueWen = {
    list:[
        {
            label:'100书券',
            value:1
        },
        {
            label:'200书券',
            value:2
        },
        {
            label:'300书券',
            value:3
        },
        {
            label:'400书券',
            value:4
        },
        {
            label:'500书券',
            value:5
        },
        {
            label:'600书券',
            value:6
        },
        {
            label:'700书券',
            value:7
        },
        {
            label:'800书券',
            value:8
        },
        {
            label:'900书券',
            value:9
        },
        {
            label:'1000书券',
            value:10
        },
    ]
}

export let activityYangGuang = {
    list:[
        {
            label:'书币天降，赠送300书币',
            value:'378201562653004691'
        },
        {
            label:' 书币狂欢，赠送500书币',
            value:'720141562653112554'
        },
        {
            label:' 书币乾坤袋，赠送200书币',
            value:'579301562653113222'
        },
        {
            label:' 书币大放送，赠送800书币',
            value:'791881582272447020'
        },
        {
            label:' 天降书币，赠送1000书币',
            value:'193011582273123129'
        },
        {
            label:' 恭喜获得，赠送100书币',
            value:'357211599710591204'
        },
        {
            label:' 免费领取，赠送600书币',
            value:'040731604043289905'
        },
        {
            label:' 福利天降，赠送400书币',
            value:'430081618994955692'
        }
    ],
    limitMax:5
}

export let activityQiYue = {
  list:[{
      label:'赠币100',
      value:'348411572922365646'
  },{
      label:'赠币200',
      value:'081721572922636420'
  },{
      label:'赠币300',
      value:'724781572922655148'
  },
{
    label:'赠币400',
    value:'335471572922674979'
},
{
    label:'赠币500',
    value:'561571572922688003'
}],
  limitMax:3
}

export let activityHuaShen = {
    list:[
        {
            label:'50书币',
             value:'50'
        },
        {
            label:"100书币",
            value:'100'
        },
        {
            label:'200书币',
            value:'200'
        },
        {
            label:'300书币',
            value:'300'
        },
        {
            label:'500书币',
            value:'500'
        },
        {
            label:'800书币',
            value:'800'
        },
        {
            label:'1000书币',
            value:'1000'
        }
    ]
}