<template>
  <!-- <el-dialog title="插入小程序"
             append-to-body
             width="500px"
             :visible.sync="show"
             :before-close="handleClose"> -->
  <div>
    <el-form :model="form" label-width="70px">
      <el-form-item label="名称" required>
        <el-input v-model="form.name" placeholder="请输入小程序名称"></el-input>
      </el-form-item>
      <el-form-item label="appid" required>
        <el-input
          v-model="form.appid"
          placeholder="请输入小程序appid"
        ></el-input>
      </el-form-item>
      <el-form-item label="路径" required>
        <el-input
          v-model="form.path"
          placeholder="示例：pages/index/index"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSure">确 定</el-button>
    </div>
  </div>
  <!-- </el-dialog> -->
</template>

<script>
export default {
  name: 'insertMiniProgram',
  props: {
    show: Boolean
  },
  data () {
    return {
      form: {}
    }
  },
  methods: {
    handleSure () {
      const { name, appid, path } = this.form
      if (!name) {
        this.$message.warning('请输入小程序名称')
        return false
      }
      if (!appid) {
        this.$message.warning('请输入小程序appid')
        return false
      }
      if (!path) {
        this.$message.warning('请输入小程序路径')
        return false
      }
      this.$emit(
        'success',
        `<a data-miniprogram-appid="${appid}" data-miniprogram-path="${path}">${name}</a>`
      )
      this.handleClose()
    },
    handleClose () {
      this.$emit('close')
      this.form = {}
    }
  }
}
</script>

<style lang='scss' scoped>
  .dialog-footer{
    margin-top:40px;
    text-align:center;
  }
</style>
