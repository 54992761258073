<template>
  <el-dialog
    title="插入书币活动"
    :visible.sync="show"
    append-to-body
    width="800px"
    :before-close="handleClose"
  >
    <div class="choose-activity">
      <div class="platform-activity" v-if='channel'>
        <div v-if='channel.platform !== 1'>
          <p style='width:70px'>充值活动</p>
          <el-radio-group class="activity-list"  v-model="activityId">
            <el-radio
              v-for="item in dataList"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </div>
        <div v-else style='display:flex;jusitfy-content:flex-start;align-items:center'>
            <p style='width:70px'>赠送书券量</p>
            <el-input-number  v-model="inputValue" @change='handleInputValue' style="width: 200px" placeholder="请输入书币" :min="0" :max="1000"></el-input-number>
        </div>
        <template v-if="limitNum !== null && channel.platform !== 1">
          <div>
            <p>充值次数</p>
            <el-select
              v-model="rechargeNum"
              placeholder="充值次数"
              style="width: 200px"
              clearable
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </template>
        <template v-else-if="channel.platform === 1">
          <div>
            <p style='width:70px'>赠送人数</p>
            <el-input-number
              style="width: 200px"
              v-model="rechargeNum"
              placeholder="请输入赠送人数"
              :min="0"
            ></el-input-number>
          </div>
        </template>
      </div>
      <div class="button-list">
        <el-button
          type="primary"
          style="margin-right: 10px !important"
          @click="handleSuccess"
          >确定</el-button
        >
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  activityHuaShen,
  activityQiYue,
  activityYangGuang,
  activityYueWen,
  activityZHY
} from './activity.js'
export default {
  props: {
    type: String,
    channel: Object,
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentView: null,
      dataList: [],
      options: [],
      activityId: null,
      limitNum: null,
      rechargeNum: 1,
      inputValue: null
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.renderData()
        } else {
          this.limitNum = null
          this.dataList = []
        }
      }

    },
    channel: {
      handler (newV) {}
    }
  },
  mounted () {},
  methods: {
    renderData () {
      if (!this.channel) return this.$message.error('请选择公众号')
      const { channelName, id, platform } = this.channel
      if (platform === 0) {
        this.dataList = activityZHY.list
        this.limitNum = null
      }
      if (platform === 1) {
        // this.dataList = activityYueWen.list;
        this.limitNum = null
      }
      if (platform === 3) {
        this.dataList = activityYangGuang.list
        this.limitNum = 5
      }
      if (platform === 6) {
        this.dataList = activityQiYue.list
        this.limitNum = 3
      }
      if (platform === 7) {
        this.dataList = activityHuaShen.list
        this.limitNum = null
      }
      if (this.limitNum) {
        this.options = []
        for (let i = 0; i < this.limitNum; i++) {
          this.options.push({
            label: `${i + 1}次`,
            value: i + 1
          })
        }
      }
      if (platform !== 1) this.activityId = this.dataList[0].value
    },
    handleInputValue (newV) {
      if (newV) {
        const str = newV.toString()
        const regExp = /\./g
        if (regExp.test(str)) {
          const str1 = str.split('.')[0]
          this.inputValue = Number(str1)
        }
      }
    },
    handleClose () {
      this.dataList = []
      this.inputValue = null
      this.activityId = null
      this.rechargeNum = 1
      this.limitNum = null
      this.$emit('close')
    },
    handleSuccess () {
      const { channelName, id, platform } = this.channel
      const { inputValue } = this
      if (platform !== 1) {
        if (this.isFalsy(this.activityId)) { return this.$message.error('请选择活动!') }
      }
      let label = ''
      if (platform === 1) {
        label = `${inputValue}书券`
      } else {
        label = this.dataList.find((item) => item.value === this.activityId).label
      }

      if ([0, 7].includes(platform)) {
        if (this.type === 'text') {
          this.$emit('success', {
            value: `<a href="{award:${this.activityId}}">${label}</a>`
          })
        }
        if (this.type === 'news') {
          this.$emit('success', {
            name: label,
            url: `{award:${this.activityId}}`
          })
        }
      }
      if (platform === 1) {
        if (!inputValue) {
          return this.$message.warning('请输入书币！')
        }
        if (this.type === 'text') {
          this.$emit('success', {
            value: `<a href="{award:${inputValue},limit:${this.rechargeNum}}">${label}+限${this.rechargeNum}人</a>`
          })
        }
        if (this.type === 'news') {
          this.$emit('success', {
            name: `${label}+限${this.rechargeNum}人`,
            url: `{award:${inputValue},limit:${this.rechargeNum}}`
          })
        }
      }
      if ([3, 6].includes(platform)) {
        if (this.type === 'text') {
          this.$emit('success', {
            value: `<a href="{award:${this.activityId},limit:${this.rechargeNum}}"> ${label} + 限购${this.rechargeNum}次</a>`
          })
        }
        if (this.type === 'news') {
          this.$emit('success', {
            name: `${label} + 限购${this.rechargeNum}次`,
            url: `{award:${this.activityId},limit:${this.rechargeNum}}`
          })
        }
      }
      this.handleClose()
    },
    isFalsy (val) {
      return val === undefined || val === null || val === NaN || val === ''
    },
    handleCancel () {
      this.handleClose()
    },
    handleChange () {}
  },
  components: {}
}
</script>
<style lang='scss' scoped>
/deep/.el-radio {
  margin-bottom: 20px;
  margin-left:0px;
  width:200px;
  max-width:50%;

}
.activity-list {
  width: 500px;
}
.choose-activity {
  padding-bottom: 20px;
  .platform-activity {
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 20px 0;
      > p {
        margin-right: 20px;
      }
      &:nth-of-type(2) {
        > p {
          line-height: 32px;
        }
      }
    }
  }
  .button-list {
    text-align: center;
  }
}
</style>
